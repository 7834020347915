<template>
    <el-card>
        <el-card-header
            :title="__('Получатели')"
        />
        <el-card-section v-if="contactBookDataSourceList.length > 1">
            <el-select
                v-model="model.contactbookDataSourceId"
                class="w-100"
            >
                <el-option
                    v-for="item in contactBookDataSourceList"
                    :key="item.id"
                    :label="item.descr"
                    :value="item.id"
                />
            </el-select>
        </el-card-section>
        <el-card-section>
            <el-form class="bordered" label-position="top">
                <sender-receiver-filters
                    v-if="receiversSelected"
                    v-model="model.filters"
                    :usluga-id="uslugaId"
                    :group-ids="model.groups"
                    :contactbook-data-source-id="model.contactbookDataSourceId"
                />

                <template
                    v-if="formParams.receiversContacts || formParams.receiversGroups"
                >
                    <el-form-item
                        v-if="receiversSelected"
                        class="without-border"
                    >
                        <sender-receivers-preview
                            :usluga-id="uslugaId"
                            :form="formParams"
                            :show-groups="formParams.receiversGroups"
                            :show-contacts="formParams.receiversContacts"
                            :recipients="model"
                            :groups="groups"
                            :contacts="contacts"
                        />
                    </el-form-item>


                    <el-form-item
                        :class="!receiversSelected ? 'without-border' : null"
                    >
                        <my-flip-container v-model="isReceiversSelectVisible" vertical>
                            <template #front>
                                <div>
                                    <div>
                                        <el-button
                                            :plain="receiversSelected"
                                            class="w-100 d-block"
                                            round
                                            type="success"
                                            @click="emits('update:is-receivers-select-visible', true)"
                                        >
                                            <template #icon>
                                                <font-awesome-icon icon="user-plus" light />
                                            </template>

                                            {{ receiversSelected ? __("Добавить ещё") : __("Добавить получателей") }}
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                            <template #back>
                                <div>
                                    <el-button
                                        class="w-100 d-block"
                                        disabled
                                        plain
                                        round
                                        style="cursor: default;"
                                    >
                                        <template #icon>
                                            <font-awesome-icon icon="arrow-left" light />
                                        </template>

                                        {{ __("Выберите получателей") }}
                                    </el-button>
                                </div>
                            </template>

                        </my-flip-container>

                    </el-form-item>
                    <el-form-item
                        v-if="!receiversSelected && formParams.receiversOtherFromString"
                        class="text-center without-border"
                    >
                        <div class="or-divider">{{ __('Или') }}</div>
                    </el-form-item>
                </template>


                <el-form-item
                    v-if="formParams.receiversOtherFromString"
                    :label="receiversSelected ? __('Другие получатели') : null"
                    :class="receiversSelected ? null : 'without-border'"
                >
                    <el-input
                        v-model="model.otherRecipientsId"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 10}"
                        :placeholder="otherRecipientsPlaceholder"
                    />
                </el-form-item>


                <template v-if="isSomeContactsSelected && formParams.receiversStop">
                    <el-form-item>
                        <template #label>
                            <el-switch
                                v-model="model.stopListEnabled"
                                class="mr-2"
                            />
                            {{ __('Стоп-лист') }}
                        </template>
                        <div>
                            <template v-if="model.stopListEnabled">
                                <el-select
                                    v-model="model.stopListGroupIds"
                                    class="w-100"
                                    multiple
                                    @update:modelValue="onStopListChange"
                                >
                                    <el-option
                                        v-for="group in sortedGroups"
                                        :key="group.id"
                                        :label="group.descr"
                                        :value="group.id"
                                    >
                                        <font-awesome-icon
                                            :color="group.color ? group.color : 'muted'"
                                            class="mr-1"
                                            icon="folder"
                                        />
                                        {{ group.descr }}
                                    </el-option>
                                </el-select>
                                <el-button
                                    v-if="isStopListChanged && stopListSaveable"
                                    class="mt-2 d-block w-100"
                                    round
                                    @click="saveStopList"
                                >
                                    {{ __("Запомнить стоп-лист") }}
                                </el-button>
                            </template>
                        </div>

                    </el-form-item>

                    <el-form-item
                        v-if="formParams.receiversLimit"
                        label-width="0px"
                    >
                        <el-switch
                            v-model="model.limit.enabled"
                            class="mr-2"
                        />
                        Лимит получателей
                        <div
                            v-if="model.limit.enabled"
                            class="mt-2"
                        >
                            <p class="lh-33">
                                Укажите порядковые номера первого и последнего получателей
                            </p>
                            <el-row tag="p">
                                <el-col :span="10">
                                    <el-input-number
                                        v-model="model.limit.first"
                                        :controls="false"
                                        :min="1"
                                        class="w-100"
                                    />
                                </el-col>
                                <el-col
                                    :span="4"
                                    class="text-center"
                                >
                                    <font-awesome-icon icon="ellipsis-h"/>
                                </el-col>
                                <el-col :span="10">
                                    <el-input-number
                                        v-model="model.limit.last"
                                        :controls="false"
                                        :min="1"
                                        class="w-100"
                                    />
                                </el-col>
                            </el-row>
                            <p class="lh-33">
                                <el-radio
                                    v-model="model.limit.random"
                                    :value="false"
                                >
                                    Выбирать абонентов по порядку
                                </el-radio>

                                <el-radio
                                    v-model="model.limit.random"
                                    :value="true"
                                >
                                    Выбирать абонентов случайно
                                </el-radio>
                            </p>


                        </div>
                    </el-form-item>

                </template>


            </el-form>
        </el-card-section>
    </el-card>
</template>

<script lang="ts">
export default {
    name: "SenderReceiversBlock"
}
</script>

<script lang="ts" setup>
import {
    ISenderFormContact, ISenderFormGroup,
    SenderFormParamsInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import SenderReceiversPreview from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiversPreview.vue";
import MyFlipContainer from "~/cabinet/vue/interface/transition/MyFlipContainer.vue";
import {IFiltersData} from "~/cabinet/vue/interface/filter/Interfaces";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import {ContactBookDataSourceInterface} from "~/cabinet/ts/store/AccountStore";
import ElCardHeader from "~/cabinet/vue/interface/card/ElCardHeader.vue";
import ElCardSection from "~/cabinet/vue/interface/card/ElCardSection.vue";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import SenderUslugi from "~/cabinet/ts/data/usluga/SenderUslugi";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {
    SenderFormReceiversInterface
} from '../useSenderContentForm';
import {useSenderReceiversComponent} from "~/cabinet/vue/client/sender/send/receiverSelect/useSenderReceiversComponent";
import {computed, ref, watch} from "vue";
import SenderReceiverFilters from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverFilters.vue";
import {__} from "~/ts/library/Translate";

let props = defineProps<{
    uslugaId: string,
    formParams: SenderFormParamsInterface,
    contacts: ISenderFormContact[],
    filters?: IFiltersData,
    groups?: ISenderFormGroup[],
    contactBookDataSourceList: ContactBookDataSourceInterface[],
    stopGroups?: { id: number, descr: string, color: string }[],
    stopListSaveable?: boolean
}>();

let isReceiversSelectVisible = defineModel<boolean>("isReceiversSelectVisible", {
    required: true
});

let emits = defineEmits<{
    (e: "update:is-receivers-select-visible", value: boolean): void
}>();

let _modelValue = defineModel<SenderFormReceiversInterface>();
let model = useSenderReceiversComponent(_modelValue);


function checkDataSource() {
    if (props.contactBookDataSourceList.length > 0) {
        if (!props.contactBookDataSourceList.find(item => item.id == model.value.contactbookDataSourceId)) {
            model.value.contactbookDataSourceId = props.contactBookDataSourceList[0].id;
        }
    }
}

checkDataSource();

watch(computed(() => model.value.contactbookDataSourceId), () => {
    checkDataSource();
})

let isStopListChanged = ref(false);

let receiversSelected = computed(() => {
    return (model.value.contacts.length + model.value.groups.length) > 0;
});

function onStopListChange() {
    isStopListChanged.value = true;
}

async function saveStopList() {
    isStopListChanged.value = false;

    let result = await LcabApiRequest.fetch({
        url: `/api/sender/saveStopList`,
        p: {
            stopList: model.value.stopListGroupIds
        },
        silent: true
    });
    if (!result.isSuccess) {
        result.showMessage();
        isStopListChanged.value = true;
    }
}

let isSomeContactsSelected = computed(() => {
    return model.value.groups.length || model.value.contacts.length || model.value.otherRecipientsId.length;
});

let sortedGroups = computed(() => {
    return [...props.stopGroups].sort(SenderReceiverSelectHelper.groupSortMethod);
});

let usluga = computed(() => {
    return SenderUslugi[props.uslugaId];
});

let otherRecipientsPlaceholder = computed(() => {
    return usluga.value?.otherRecipientPlaceholder;
});
</script>

<style scoped>
.or-divider {
    margin: -.5rem 0 -.7rem;
}
</style>

