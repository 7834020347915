<template>
    <div class="sender-preview">
        <my-icon-button
            delete
            class="float-right ml-2 no-border"
            near-text
            @click="emits('delete')"
        />

        <div :style="{paddingRight: paddingRight}" class="text-truncate">
            <span class="mr-1">
                <slot name="icon"/>
            </span>
            <span :class="lineThrough ? 'excluded' : ''">
                {{ descr }}
            </span>
            <span v-if="count != null" ref="countSpan" class="count">
                {{ count }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "SenderReceiverPreview"
}
</script>

<script lang="ts" setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import MyIconButton from "~/cabinet/vue/interface/button/MyIconButton.vue";

let props = defineProps<{
    descr: string,
    lineThrough?: boolean,
    count?: number | string
}>();

let emits = defineEmits<{
    (e: "delete"): void
}>();

let countSpan = ref<HTMLElement>();

let paddingRight = ref<string>();

watch(computed(() => props.count), () => {
    nextTick(() => {
        updatePaddingRight();
    });
})

function updatePaddingRight() {
    let result: string;
    if (props.count != null) {
        let el = countSpan.value;
        if (el) {
            result = (el.offsetWidth + 7) + "px";
        }
    }
    paddingRight.value = result;
}

onMounted(() => updatePaddingRight());
</script>

<style scoped>
.sender-preview {
    clear: both;
}

.excluded {
    text-decoration: line-through;
}

.count {
    position: absolute;
    right: 0;
}

.text-truncate {
    position: relative;
}
</style>

