<template>
    <my-transition-slide-x>
        <el-form-item
            v-for="(row, index) in rows"
            :key="row.id"
            :class="['receiver-preview', index < rows.length - 1 ? 'without-border' : '']"
        >
            <template #label>
                <my-form-item-label-link @click="row.originalItems.splice(0, row.count)">
                    {{ __('Очистить') }}
                </my-form-item-label-link>
                {{ row.descr }}
            </template>
            <my-transition-slide-x>
                <sender-receiver-preview
                    v-for="(item, index) in row.items"
                    :key="item.id"
                    :count="row.id === 'groups' ? getGroupCount(item.id) : null"
                    :descr="row.id === 'groups' ? item.descr : contactDescr(item)"
                    :line-through="row.id === 'contacts' && isContactExcluded(item.id)"
                    :type="row.id"
                    @delete="row.originalItems.splice(index, 1)"
                >
                    <template #icon>
                        <receiver-group-icon
                            v-if="row.id === 'groups'"
                            :group="item"
                        />
                        <font-awesome-icon
                            v-else
                            color="muted"
                            icon="user"
                            light
                        />
                    </template>
                </sender-receiver-preview>
            </my-transition-slide-x>
        </el-form-item>
    </my-transition-slide-x>
</template>

<script lang="ts">
export default {
    name: "SenderReceiversPreview"
}
</script>

<script lang="ts" setup>
import {computed, onMounted, ref, watch} from 'vue';
import {
    ISenderFormContact,
    ISenderFormGroup,
    SenderFormParamsInterface
} from "~/cabinet/vue/client/sender/send/Interfaces";
import SenderReceiverPreview from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverPreview.vue";
import SenderReceiverSelectHelper from "~/cabinet/vue/client/sender/send/receiverSelect/SenderReceiverSelectHelper";
import ReceiverGroupIcon from "~/cabinet/vue/client/sender/send/receiverSelect/ReceiverGroupIcon.vue";
import MyTransitionSlideX from "~/cabinet/vue/interface/transition/MyTransitionSlideX.vue";
import MyFormItemLabelLink from "~/cabinet/vue/interface/form/MyFormItemLabelLink.vue";
import {__} from "~/ts/library/Translate";
import Delay from "~/ts/library/Delay";
import Dictionary from "~/ts/library/Dictionary";
import ObjectHelper from "~/ts/library/ObjectHelper";
import {
    PHONEBOOK_COLUMN_NAME_EMAIL,
    PHONEBOOK_COLUMN_NAME_FIRSTNAME,
    PHONEBOOK_COLUMN_NAME_LASTNAME,
    PHONEBOOK_COLUMN_NAME_MIDDLENAME,
    PHONEBOOK_COLUMN_NAME_PHONE
} from "~/cabinet/vue/client/phonebook/contacts/constants";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";
import {SenderFormReceiversInterface} from "~/cabinet/vue/client/sender/send/useSenderContentForm";

let props = withDefaults(defineProps<{
    recipients: SenderFormReceiversInterface,
    uslugaId: string,
    groups?: ISenderFormGroup[],
    contacts: ISenderFormContact[],
    form: SenderFormParamsInterface,
    showGroups?: boolean,
    showContacts?: boolean
}>(), {
    showGroups: true,
    showContacts: true
});

let recipientsCount = ref<{
    groups: Dictionary<number>,
    excludedContacts: number[]
}>();

async function recountRecipients() {
    try {
        await Delay.make(1000, "SenderReceiversPreviewRecount", true);
        let result = await LcabApiRequest.fetch({
            url: `/api/sender/${props.uslugaId}/${props.form.id}/getRecountReceivers`,
            p: {recipients: props.recipients},
            silent: true
        });
        if (result.isSuccess) {
            recipientsCount.value = result.getData();
        }
    } catch (e) {

    }
}

watch(computed(() => props.recipients), () => recountRecipients(), {deep: true});

onMounted(() => {
    recountRecipients();
});

let groupsCount = computed(() => {
    return props.recipients && props.recipients.groups ? props.recipients.groups.length : 0;
});

let contactsCount = computed(() => {
    return props.recipients && props.recipients.contacts ? props.recipients.contacts.length : 0;
});

let receiverIdField = computed(() => {
    return SenderReceiverSelectHelper.getReceiverIdFieldByUslugaId(props.uslugaId);
});

let groupsDictionary = computed(() => {
    return props.groups ? ObjectHelper.dictionaryFromArray(props.groups, "id") : {};
});

let rows = computed(() => {
    let result: any[] = [];
    if (groupsCount.value && props.showGroups) {
        result.push({
            id: "groups",
            count: groupsCount.value,
            originalItems: props.recipients.groups,
            items: props.recipients.groups.map(groupId => {
                let group: ISenderFormGroup = props.groups ? props.groups.find(group => group.id == groupId) : null;
                if (!group) {
                    group = {
                        id: groupId,
                        descr: `Группа #${groupId}`,
                        color: 'gray',
                        count: 0,
                        isHidden: false,
                        isStop: false
                    };
                }
                return group;
            }),
            descr: __("Группы")
        });
    }
    if (contactsCount.value && props.showContacts) {
        result.push({
            id: "contacts",
            count: contactsCount.value,
            originalItems: props.recipients.contacts,
            items: props.recipients.contacts.map(contactId => {
                let contact: ISenderFormContact = props.contacts.find(contact => contact.id == contactId);
                if (!contact) {
                    contact = {
                        id: contactId,
                        groupId: 0,
                        [PHONEBOOK_COLUMN_NAME_LASTNAME]: `Контакт #${contactId}`,
                        [PHONEBOOK_COLUMN_NAME_FIRSTNAME]: "",
                        [PHONEBOOK_COLUMN_NAME_MIDDLENAME]: "",
                        [PHONEBOOK_COLUMN_NAME_EMAIL]: "",
                        [PHONEBOOK_COLUMN_NAME_PHONE]: ""
                    };
                }
                return contact;
            }),
            descr: __("Контакты")
        });
    }
    return result;
});

function contactDescr(contact: ISenderFormContact): string {
    let result = (contact as any)[receiverIdField.value];
    if (!result) {
        result = "";
    }
    let fullName = SenderReceiverSelectHelper.getFullNameByContact(contact);
    if (fullName != null) {
        if (result && result.length) {
            result += ", ";
        }
        result += fullName;
    }
    return result;
}

function isContactExcluded(id: number): boolean {
    return recipientsCount.value && recipientsCount.value.excludedContacts.indexOf(id) > -1;
}

function getGroupCount(id: number): string | null | number {
    let result;
    let group = groupsDictionary.value[id];
    if (group) {
        let filteredCount = recipientsCount.value ? recipientsCount.value.groups[id] : null;
        result = filteredCount != null && filteredCount != group.count ? __("%1$s из %2$s", filteredCount, group.count) : group.count;
    }
    return result;
}

</script>

<style scoped>
.receiver-preview + .receiver-preview {
    margin-top: .8rem
}
</style>