<template>
    <el-button
        :class="['no-border', nearText ? 'round-icon-button-near-text' : '']"
        :disabled="disabled"
        link
        @click="emits('click')"
    >
        <font-awesome-icon :icon="computedIcon" />
    </el-button>
</template>

<script lang="ts">
export default {
    name: "MyIconButton"
}
</script>

<script lang="ts" setup>
import {computed} from "vue";
import {iconLight} from "~/ts/vuePlugins/IconPlugin";
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";

let props = defineProps<{
    icon?: string,
    nearText?: boolean,
    disabled?: boolean,
    add?: boolean,
    delete?: boolean,
    edit?: boolean
}>();

let emits = defineEmits<{
    (e: 'click'): void
}>();

let computedIcon = computed(() => {
    let icon: string;
    if (props.add) {
        icon = 'plus'
    } else if (props.delete) {
        icon = 'times'
    } else if (props.edit) {
        icon = 'pencil'
    }
    return icon ? iconLight(icon) : props.icon;
});

</script>

<style>
    .round-icon-button-near-text {
        line-height: inherit !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
</style>

