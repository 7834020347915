<template>
    <component
        :href="href"
        :is="to ? 'router-link' : 'a'"
        :to="to"
        @click="onClick"
    >
        <el-dropdown-item
            :class=colorClass
            :divided="divided"
        >
            <font-awesome-icon
                :class="['icon', colorClass]"
                v-if="icon"
                :icon="icon"
            />
            <slot/>
        </el-dropdown-item>
    </component>
</template>

<script lang="ts">
export default {
    name: "MyDropdownLinkItem"
}
</script>

<script lang="ts" setup>
import {computed} from 'vue';
import FontAwesomeIcon from "~/cabinet/vue/interface/icon/FontAwesomeIcon.vue";

let props = defineProps<{
    to?: string | object,
    href?: string,
    icon?: string,
    color?: string,
    divided?: boolean
}>();

let emits = defineEmits<{
    (e: "click"): void
}>();

let colorClass = computed(() => {
    return props.color ? 'text-' + props.color : '';
});

function onClick(e: MouseEvent) {
    if (!props.to && !props.href) {
        e.preventDefault();
    }
    emits("click");
}
</script>

<style scoped>
a {
    text-decoration: none !important;
}

.icon {
    margin-right: .5em;
}
</style>

